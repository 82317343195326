import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import "../components/LandingPage/landingpage.scss"
//import Logo from "../../assets/svg/logo-color.svg"
import * as Sentry from "@sentry/react";

let lang = "en";
let description = "Welcome to the SWEP virtual showroom where you can experience our products.";
let email = "Email";
let header = "SWEP Virtual Showroom";
let enter = "Enter";
if (typeof window !== 'undefined') {
  if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { 
    lang = "cn"; 
    description = "欢迎来到舒瑞普（SWEP）数字展厅并开启您的产品体验之旅。请在下方输入您的邮件地址";
    email = "邮件地址";
    enter = "确认";
    header = "舒瑞普（SWEP）数字展厅";
  };
  if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { 
    lang = "de"; 
    description = "Willkommen auf dem virtuellen Messestand von SWEP, auf dem Sie unsere Produkte entdecken können. Willkommen auf dem virtuellen Messestand von SWEP, auf dem Sie unsere Produkte entdecken können.";
    email = "E-Mail Adresse";
  };
  if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { 
    lang = "fr"; 
    description = "Bienvenu dans le salon virtuel de SWEP où vous pourrez découvrir nos produits. Prière svp de saisir votre email pour";
    enter = "Entrer";
  };
  if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { 
    lang = "pl"; 
    description = "Witamy w cyfrowym salonie SWEP, w którym można zapoznać się z naszymi produktami. Wpisz swój adres e-mail, aby wejść."
    header = "Cyfrowy Salon SWEP";
    email = "Adres e-mail";
    enter = "Wejdź";
  };
}

Sentry.init({
  dsn: "https://315a8168cd7849399ed7b4ea4bb38346@o123838.ingest.sentry.io/5435566",
  tracesSampleRate: 1.0,
});

const LandingPage = () => (
  <div className="landing-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{header}</title>
          <link rel="canonical" href="https://swepvirtualtradeshow.com/" />
          <meta property='og:title' content='SWEP Virtual Showroom'/>
          <meta property='og:image' content='/preview.jpg'/>
          <meta property='og:description' content='Welcome to the SWEP virtual showroom where you can experience our products.'/>
          <meta property='og:url' content='https://swepvirtualtradeshow.com/'/>          
        </Helmet>  
      <div className="jumbo">
        {/* <Logo className="jumbo-logo"/> */}
        <p id="jumbo-title">{header}</p>
        <p id="landing-paragraph">{description}</p>
        <p id="landing-paragraph">
          <button onClick={() => {
            navigate("/district-energy")
          }}>District Energy</button>
        </p>

        <p id="landing-paragraph">
          <button onClick={() => {
            navigate("/heat-recovery")
          }}>Heat Recovery</button>
        </p>
        {/* <form className="landing-form" onSubmit={event => {
          event.preventDefault(); 
          let email = event.target.email.value;
          Sentry.captureException("Login: " + email);
          navigate("/showroom?lang=" + lang)
        }}>
            <input name="email" type="text" placeholder={email}></input>
            <button type="submit">{enter}</button>
        </form> */}
      </div>
  </div>
)

export default LandingPage
